import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  root: {
    minHeight: rem(50.5),
  },
  stretch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(4),
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  review: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(4),
  },
  ellipse: {
    overflow: 'hidden',
    maxWidth: rem(125),
    textOverflow: 'ellipsis',
  },
  star: {
    color: palette.supporting.amber[500],
  },
  tagNewTeacher: {
    backgroundColor: palette.supporting.blue[900],
  },
}));
