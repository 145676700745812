import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'FavoriteRemoveToast.header',
    defaultMessage: `{teacherGender, select, 
      female {{teacherFirstName} a été supprimée des favoris}
      other {{teacherFirstName} a été supprimé des favoris}
    }`,
  },
  description: {
    id: 'FavoriteRemoveToast.description',
    defaultMessage:
      'Vous pouvez accéder à vos favoris depuis votre tableau de bord.',
  },
});
