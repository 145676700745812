import { useCallback, useState } from 'react';
import { Announce, User } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

import FavoriteAddToast from '@/shared/Toast/FavoriteAddToast/FavoriteAddToast';
import FavoriteRemoveToast from '@/shared/Toast/FavoriteRemoveToast/FavoriteRemoveToast';
import { useToastContext } from '@/shared/Toast/Toast.context';

const useAnnounceFavorite = ({
  announceId,
  isInitialFavorite = false,
  teacher,
}: {
  announceId: Announce['id'];
  isInitialFavorite?: boolean;
  teacher: {
    firstName: User['firstName'];
    gender: User['gender'];
  };
}) => {
  const [isFavorite, setIsFavorite] = useState(isInitialFavorite ?? false);
  const { addToast } = useToastContext();

  const toggleFavorite = useCallback(async () => {
    const fetchData = async () => {
      const {
        data: { isFavoriteAnnounce },
      } = await axios.post(
        `${server}announce/private/favorite`,
        { announceId },
        {
          validateStatus: (status) => status === 201,
          withCredentials: true,
        }
      );
      setIsFavorite(isFavoriteAnnounce);
      addToast(
        isFavoriteAnnounce ? (
          <FavoriteAddToast
            teacherFirstName={teacher.firstName}
            teacherGender={teacher.gender}
          />
        ) : (
          <FavoriteRemoveToast
            teacherFirstName={teacher.firstName}
            teacherGender={teacher.gender}
          />
        )
      );
    };

    await fetchData();
  }, [addToast, announceId, teacher]);

  return {
    isFavorite,
    toggleFavorite,
  };
};

export default useAnnounceFavorite;
