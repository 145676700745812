import { FC, ReactElement, useReducer } from 'react';

interface HoverProps {
  // eslint-disable-next-line no-unused-vars
  renderItem: ({ isHovered }: { isHovered: boolean }) => ReactElement;
}

const Hover: FC<HoverProps> = ({ renderItem }) => {
  const [isHovered, toggle] = useReducer((isHovered) => !isHovered, false);

  return (
    <div onMouseEnter={toggle} onMouseLeave={toggle} onTouchStart={toggle}>
      {renderItem({ isHovered })}
    </div>
  );
};

export default Hover;
