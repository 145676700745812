import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRating, minutesToCeilHours } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import Icon from '@/designSystem/Icon/Icon';
import Tag from '@/designSystem/Tag/Tag';
import CertifiedIconTooltip from '@/shared/SearchResultCard/CertifiedIconTooltip/CertifiedIconTooltip';

import messages from './messages';

import useStyles from './styles';

type Props = {
  firstName: string;
  city: string;
  rating: number;
  reviews: number;
  minutes: number;
  isVerifiedAndTrained: boolean;
};

const CardInfos: FC<Props> = ({
  firstName,
  city,
  rating,
  reviews,
  minutes,
  isVerifiedAndTrained,
}) => {
  const { classes, cx } = useStyles();
  const hasReview = reviews > 0;
  const isNewTeacher = minutes <= 0 && !hasReview;

  return (
    <div className={classes.root}>
      <div className={classes.stretch}>
        <div className={classes.infos}>
          <div className={classes.nameRow}>
            <Typography
              variant="bodyLgBold"
              component="span"
              className={classes.ellipse}
              noWrap
            >
              {firstName}
            </Typography>
            {isVerifiedAndTrained && <CertifiedIconTooltip />}
          </div>
          <Typography
            variant="bodyMd"
            component="span"
            color="text.secondary"
            noWrap
            className={classes.ellipse}
          >
            {city}
          </Typography>
        </div>

        <div className={cx(classes.infos, classes.alignRight)}>
          {isNewTeacher && (
            <Tag
              className={classes.tagNewTeacher}
              size="small"
              label={<FormattedMessage {...messages.nouveau} />}
            />
          )}

          {hasReview && (
            <div className={classes.review}>
              <Icon icon="star" color="attention" />
              <Typography variant="bodyLgBold" component="span" noWrap>
                {formatRating(rating)}
              </Typography>
              <Typography
                variant="bodyMd"
                component="span"
                color="text.secondary"
              >
                <FormattedMessage {...messages.review} values={{ reviews }} />
              </Typography>
            </div>
          )}

          <div>
            {!isNewTeacher && (
              <Typography
                variant="bodyMd"
                component="span"
                color="text.secondary"
              >
                <FormattedMessage
                  {...messages.hours}
                  values={{ hours: minutesToCeilHours({ minutes }) }}
                />
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfos;
