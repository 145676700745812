import { PermissionResourceAndLevel } from '@les-sherpas/sherpas-toolbox';

import { AuthenticationType } from '@/components/Authentication/Authentication.utils';

type Props = {
  authentication?: AuthenticationType;
  permission: PermissionResourceAndLevel;
  isAppliedOnlyWhenConnected?: boolean;
};

const isAllowedToAccessResource = ({
  authentication,
  permission,
  isAppliedOnlyWhenConnected = false,
}: Props): boolean => {
  const isAllowedToAccessResourceAsUnauthenticatedUser =
    isAppliedOnlyWhenConnected && !authentication?.isAuthenticated;

  const isAllowedToAccessResourceAsAuthenticatedUser = permission
    ? authentication?.authData?.permissions?.includes(permission)
    : true;

  const isAllowedToAccessResource =
    isAllowedToAccessResourceAsUnauthenticatedUser ||
    isAllowedToAccessResourceAsAuthenticatedUser;

  return isAllowedToAccessResource;
};

export default isAllowedToAccessResource;
