import { defineMessages } from 'react-intl';

export default defineMessages({
  hours: {
    id: 'resultCard.infos.hours',
    defaultMessage: `{hours}h {hours, plural,
    =0 {donnée}
    =1 {donnée}
    other {données} 
    }`,
  },
  review: {
    id: 'resultCard.infos.review',
    defaultMessage: '({reviews} avis)',
  },
  nouveau: {
    id: 'resultCard.infos.nouveau',
    defaultMessage: 'Nouveau',
  },
});
