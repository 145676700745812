import { FC, PropsWithChildren } from 'react';
import { PermissionResourceAndLevel } from '@les-sherpas/sherpas-toolbox';

import useAuthentication from '@/components/Authentication/useAuthentication';
import isAllowedToAccessResource from '@/shared/Permission/is-allowed-to-access-resource';

type Props = {
  permission: PermissionResourceAndLevel;
  isAppliedOnlyWhenConnected?: boolean;
};

const Permission: FC<PropsWithChildren<Props>> = ({
  children,
  permission,
  isAppliedOnlyWhenConnected = false,
}) => {
  const { authentication } = useAuthentication();

  return isAllowedToAccessResource({
    authentication,
    permission,
    isAppliedOnlyWhenConnected,
  }) ? (
    <>{children}</>
  ) : null;
};

export default Permission;
