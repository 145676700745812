import { FC, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

type Props = {
  label?: string;
  icon: ReactElement;
  isExtended?: boolean;
  ariaLabel?: string;
};

const CardChip: FC<PropsWithChildren<Props>> = ({
  label = '',
  icon,
  isExtended = false,
  ariaLabel,
}) => {
  const { classes } = useStyles({ isExtended });

  return (
    <div
      className={classes.container}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {icon}
      {isExtended && (
        <Typography variant="bodySmBold" component="span" color="white" noWrap>
          {label}
        </Typography>
      )}
    </div>
  );
};

export default CardChip;
