import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

interface Props {
  isHovered: boolean;
}

export default makeStyles<Props>()(({ palette }, { isHovered }) => ({
  quickAnswerContainer: {
    position: 'relative',
    top: rem(4),
  },
  container: {
    aspectRatio: '307/260',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: rem(16),
    background: palette.neutral.light,
    boxShadow: '0px 8px 12px -4px #18181B14',
  },
  availableIcon: {
    color: palette.common.white,
    backgroundColor: palette.supporting.green[500],
  },
  chips: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
    padding: `${rem(16)} ${rem(12)}`,
  },
  chipsWithoutCurrentInstitution: {
    flexDirection: 'column-reverse',
  },
  icon: {
    minHeight: rem(20),
    minWidth: rem(20),
    borderRadius: rem(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  picture: {
    objectFit: 'cover',
    transition: 'transform 0.5s ease',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
  },
  schoolIcon: {
    color: palette.neutral[200],
    backgroundColor: palette.common.white,
    padding: rem(2),
  },
  favoriteIcon: {
    position: 'absolute',
    padding: `${rem(10)} ${rem(10)}`,
    backgroundColor: 'transparent',
    right: 0,
  },
}));
