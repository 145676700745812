import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import LightningBolt from '@/Atoms/Icons/LightningBolt';
import themeFoundation from '@/designSystem/theme';

import messages from './messages';

import useStyles from './styles';

const QuickAnswer = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.quickAnswer}>
      <LightningBolt size={16} className={classes.icon} />
      <Typography
        variant="bodySmBold"
        component="span"
        color={themeFoundation.palette.neutral.dark}
      >
        <FormattedMessage {...messages.answer} />
      </Typography>
    </div>
  );
};

export default QuickAnswer;
