import { useCallback, useEffect, useState } from 'react';

import useAuthentication from '@/components/Authentication/useAuthentication';
import useLocalStorage from '@/hooks/useLocalStorage';

const useAnnounceClickCounter = () => {
  const { authentication, isLoading } = useAuthentication();
  const { getItem, setItem } = useLocalStorage<number[]>(
    'number_of_announcements_visited'
  );
  const [isThirdVisitedAnnounce, setIsThirdVisitedAnnounce] = useState(false);

  const incrementVisitedAnnounceCounter = useCallback(
    (announceId: number) => {
      const items = getItem() ?? [];
      setItem([...items, announceId]);
    },
    [getItem, setItem]
  );

  useEffect(() => {
    const items = getItem();
    const isUserAuthenticated = authentication?.isAuthenticated;
    if (items?.length === 3 && !isLoading && !isUserAuthenticated) {
      setIsThirdVisitedAnnounce(true);
    }
  }, [authentication?.isAuthenticated, isLoading, getItem, setItem]);

  return {
    incrementVisitedAnnounceCounter,
    isThirdVisitedAnnounce,
  };
};

export default useAnnounceClickCounter;
