import { defineMessages } from 'react-intl';

export default defineMessages({
  tooltip: {
    id: 'resultCard.infos.tooltip',
    defaultMessage: `<b>Certification Sherpa</b><enter></enter>
    ✅ Identité vérifiée<enter></enter>
    ✅ Méthodologie Sherpas<enter></enter>
    ✅ Diplôme vérifié`,
  },
});
