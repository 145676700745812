import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(4),
    color: palette.supporting.blue[300],
  },
}));
