import { alpha } from '@mui/material/styles';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  isExtended: boolean;
};

export default makeStyles<Props>()(({ palette }, { isExtended }) => ({
  container: {
    height: rem(28),
    minWidth: rem(28),
    maxWidth: rem(220),
    background:
      'linear-gradient(0deg, rgba(39, 39, 42, 0.8), rgba(39, 39, 42, 0.8))',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: rem(16),
    gap: rem(6),
    padding: isExtended ? `${rem(2)} ${rem(12)} ${rem(2)} ${rem(2)}` : rem(2),
    border: `1px solid ${alpha(palette.neutral[300], 0.66)}`,
    transition: 'all 0.5s ease',
  },
}));
