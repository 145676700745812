import { FC, MouseEventHandler } from 'react';
import { Announce, SearchResult, User } from '@les-sherpas/sherpas-toolbox';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import Heart from '@/Atoms/Icons/Heart';
import useAuthentication from '@/components/Authentication/useAuthentication';
import { DASHFAVORITES } from '@/data/navbar/navButtons';
import useAnnounceFavorite from '@/hooks/useAnnounceFavorite';

import useStyles from './styles';

type Props = {
  announceId: Announce['id'];
  isInitialFavorite: boolean;
  onFavoriteClick: () => void;
  showLoginSignupModal?: () => void;
  teacher: {
    firstName: User['firstName'];
    gender: User['gender'];
  };
};

const FavoriteButton: FC<Props> = ({
  announceId,
  isInitialFavorite,
  onFavoriteClick,
  showLoginSignupModal = () => {},
  teacher,
}) => {
  const { authentication } = useAuthentication();
  const { refetch } = useQuery<SearchResult[]>([
    `announce/private/favorite/search`,
  ]);
  const { route } = useRouter();

  const { isFavorite, toggleFavorite } = useAnnounceFavorite({
    announceId,
    isInitialFavorite,
    teacher,
  });
  const { classes } = useStyles({ isFavorite });

  const handleFavoriteClick: MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    if (!authentication?.authData?.userType) {
      showLoginSignupModal();
      return;
    }
    await toggleFavorite();

    const shouldRefetch = route === DASHFAVORITES;
    if (shouldRefetch) {
      refetch();
    }
    onFavoriteClick();
  };

  return (
    <button
      className={classes.container}
      onClick={handleFavoriteClick}
      type="button"
    >
      <Heart className={classes.heart} />
    </button>
  );
};

export default FavoriteButton;
