import { FC, HTMLAttributes } from 'react';

import { IconProps } from '@/Atoms/Icons/Icons.type';
import themeFoundation from '@/designSystem/theme';

const OfficeIcon: FC<IconProps & HTMLAttributes<HTMLDivElement>> = ({
  size = 16,
  className,
  color = themeFoundation.palette.neutral[300],
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20002 3.20001C3.20002 2.31635 3.91637 1.60001 4.80002 1.60001H11.2C12.0837 1.60001 12.8 2.31635 12.8 3.20001V12.8C13.2419 12.8 13.6 13.1582 13.6 13.6C13.6 14.0418 13.2419 14.4 12.8 14.4H10.6C10.0477 14.4 9.60003 13.9523 9.60003 13.4V12C9.60003 11.5582 9.24185 11.2 8.80002 11.2H7.20002C6.7582 11.2 6.40002 11.5582 6.40002 12V13.4C6.40002 13.9523 5.95231 14.4 5.40002 14.4H3.20002C2.7582 14.4 2.40002 14.0418 2.40002 13.6C2.40002 13.1582 2.7582 12.8 3.20002 12.8V3.20001ZM5.60002 4.00001H7.20002V5.60001H5.60002V4.00001ZM7.20002 7.20001H5.60002V8.80001H7.20002V7.20001ZM8.80002 4.00001H10.4V5.60001H8.80002V4.00001ZM10.4 7.20001H8.80002V8.80001H10.4V7.20001Z"
      fill={color}
    />
  </svg>
);

export default OfficeIcon;
