import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  isFavorite: boolean;
};

export default makeStyles<Props, 'heart'>()(
  ({ palette: { supporting, common } }, { isFavorite }, classes) => ({
    container: {
      background:
        'linear-gradient(0deg, rgba(39, 39, 42, 0.8), rgba(39, 39, 42, 0.8))',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      width: rem(40),
      height: rem(40),
      [`& .${classes.heart}`]: {
        stroke: isFavorite ? supporting.rose[600] : common.white,
        fill: isFavorite ? supporting.rose[600] : 'transparent',
      },
      [`&:hover .${classes.heart}`]: {
        stroke: isFavorite ? supporting.rose[600] : common.white,
        fill: isFavorite ? supporting.rose[600] : common.white,
      },
    },
    heart: {},
  })
);
