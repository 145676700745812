import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Typography } from '@mui/material';

import IconCertified from '@/Atoms/Icons/IconCertified';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';

import messages from './messages';

import useStyles from './styles';

const CertifiedIconTooltip: FC = () => {
  const { classes } = useStyles();

  return (
    <Tooltip
      title={
        <Typography variant="bodySm" component="span" color="white">
          <FormattedMessage
            {...messages.tooltip}
            values={{
              enter: EnterChunk,
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Typography>
      }
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltipContainer,
        arrow: classes.tooltipArrow,
        tooltipArrow: classes.tooltipArrow,
      }}
    >
      <div className={classes.icon}>
        <IconCertified size={16} />
      </div>
    </Tooltip>
  );
};

export default CertifiedIconTooltip;
