import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from '@les-sherpas/sherpas-toolbox';

import Heart from '@/Atoms/Icons/Heart';
import themeFoundation from '@/designSystem/theme';
import Toast, { DEFAULT_CLOSE_DURATION } from '@/shared/Toast/common/Toast';
import { useToastContext } from '@/shared/Toast/Toast.context';

import messages from './messages';

type Props = {
  teacherFirstName: User['firstName'];
  teacherGender: User['gender'];
};

const FavoriteAddToast: FC<Props> = ({ teacherFirstName, teacherGender }) => {
  const { onClose } = useToastContext();

  return (
    <Toast
      open
      autoHideDuration={DEFAULT_CLOSE_DURATION}
      header={
        <FormattedMessage
          {...messages.header}
          values={{ teacherFirstName, teacherGender }}
        />
      }
      description={<FormattedMessage {...messages.description} />}
      icon={
        <Heart
          color={themeFoundation.palette.supporting.rose[600]}
          stroke={themeFoundation.palette.supporting.rose[600]}
          strokeWidth={2}
        />
      }
      onClose={onClose}
    />
  );
};

export default FavoriteAddToast;
