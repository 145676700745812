import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  quickAnswer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: rem(12),
    height: rem(40),
    backgroundColor: palette.supporting.amber[600],
    top: rem(-20),
    left: 0,
    right: 0,
    padding: rem(1),
  },
  icon: {
    marginTop: rem(1),
  },
}));
