import { FC } from 'react';
import { useIntl } from 'react-intl';
import { User, UserType } from '@les-sherpas/sherpas-toolbox';
import Image from 'next/image';

import CalendarSolidIcon from '@/Atoms/Icons/CalendarSolidIcon';
import OfficeIcon from '@/Atoms/Icons/OfficeIcon';
import { SEARCH } from '@/data/navbar/navButtons';
import LoginSignUpModalWrapper from '@/shared/LoginSignupModalWrapper/LoginSignupModalWrapper';
import CardChip from '@/shared/SearchResultCard/CardChip/CardChip';
import FavoriteButton from '@/shared/TeacherPicture/FavoriteButton/FavoriteButton';
import QuickAnswer from '@/shared/TeacherPicture/QuickAnswer/QuickAnswer';
import { BLUR_IMAGE, getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

type Props = {
  announceId: number;
  isFavoriteAnnounce: boolean;
  isFavoriteHidden?: boolean;
  teacher: {
    photoUrl: string;
    firstName: string;
    currentInstitution: string | null;
    hasConfirmedAvailabilities: boolean;
    lastName: string;
    rating: number | null;
    isQuickAnswer: boolean;
    reviewsCount: number;
    isVerifiedAndTrained: boolean;
    gender: User['gender'];
  };
  isHovered: boolean;
  userType: UserType;
  onFavoriteClick: () => void;
  className?: string;
};

const TeacherPicture: FC<Props> = ({
  announceId,
  isFavoriteAnnounce,
  teacher,
  isHovered,
  userType,
  onFavoriteClick,
  className,
  isFavoriteHidden = false,
}) => {
  const { classes, cx } = useStyles({ isHovered });
  const { formatMessage } = useIntl();
  const origin =
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_FRONT_URL
      : window.location.origin;
  const drawerExitUrl = `${origin}/${SEARCH}`;

  return (
    <div className={classes.quickAnswerContainer}>
      {teacher.isQuickAnswer && <QuickAnswer />}
      <div className={cx(classes.container, className)}>
        <Image
          priority
          fetchPriority="high"
          loading="eager"
          src={teacher.photoUrl}
          loader={getImageFromCloudImage}
          alt={`photo profil de ${teacher.firstName}`}
          className={classes.picture}
          blurDataURL={BLUR_IMAGE}
          fill
          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
        />
        <div
          className={
            teacher.currentInstitution
              ? classes.chips
              : cx(classes.chips, classes.chipsWithoutCurrentInstitution)
          }
        >
          {teacher.currentInstitution && (
            <CardChip
              label={teacher.currentInstitution}
              icon={
                <OfficeIcon className={cx(classes.icon, classes.schoolIcon)} />
              }
              isExtended
            />
          )}
          {teacher.hasConfirmedAvailabilities && (
            <CardChip
              label={formatMessage(messages.availability)}
              icon={
                <CalendarSolidIcon
                  size={22}
                  className={cx(classes.icon, classes.availableIcon)}
                  color="white"
                />
              }
              isExtended={isHovered}
              ariaLabel={formatMessage(messages.availabilityAriaLabel)}
            />
          )}
        </div>
        {userType !== UserType.Teacher && !isFavoriteHidden && (
          <div className={classes.favoriteIcon}>
            <LoginSignUpModalWrapper
              origin="announce"
              initialVariant="signup"
              drawerExitUrl={drawerExitUrl}
              teacher={teacher}
              renderItem={({ toggle }) => (
                <FavoriteButton
                  teacher={teacher}
                  announceId={announceId}
                  isInitialFavorite={isFavoriteAnnounce}
                  onFavoriteClick={onFavoriteClick}
                  showLoginSignupModal={toggle}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherPicture;
