import { defineMessages } from 'react-intl';

export default defineMessages({
  availability: {
    id: 'resultCard.availability',
    defaultMessage: 'Disponibilité confirmée',
  },
  availabilityAriaLabel: {
    id: 'resultCard.availability.ariaLabel',
    defaultMessage: 'Disponibilité',
  },
});
