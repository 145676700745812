import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'FavoriteAddToast.header',
    defaultMessage: `{teacherGender, select, 
      female {{teacherFirstName} est ajoutée aux favoris}
      other {{teacherFirstName} est ajouté aux favoris}
    }`,
  },
  description: {
    id: 'FavoriteAddToast.description',
    defaultMessage:
      'Vous pouvez accéder à vos favoris depuis votre tableau de bord.',
  },
});
