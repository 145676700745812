import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from '@les-sherpas/sherpas-toolbox/';

import Icon from '@/designSystem/Icon/Icon';
import Toast, { DEFAULT_CLOSE_DURATION } from '@/shared/Toast/common/Toast';
import { useToastContext } from '@/shared/Toast/Toast.context';

import messages from './messages';

type Props = {
  teacherFirstName: string;
  teacherGender: User['gender'];
};

const FavoriteRemoveToast: FC<Props> = ({
  teacherFirstName,
  teacherGender,
}) => {
  const { onClose } = useToastContext();

  return (
    <Toast
      open
      autoHideDuration={DEFAULT_CLOSE_DURATION}
      header={
        <FormattedMessage
          {...messages.header}
          values={{ teacherFirstName, teacherGender }}
        />
      }
      description={<FormattedMessage {...messages.description} />}
      icon={<Icon icon="trash" color="danger" size="large" />}
      onClose={onClose}
    />
  );
};

export default FavoriteRemoveToast;
