import { FC } from 'react';

type Props = {
  color?: string;
  stroke?: string;
  strokeWidth?: number;
  className?: string;
};

const Heart: FC<Props> = ({
  color = 'transparent',
  stroke = 'white',
  strokeWidth = 2,
  className,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
      fill={color}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Heart;
