import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  tooltip: {
    color: palette.common.white,
  },
  tooltipContainer: {
    backgroundColor: palette.neutral[200],
  },
  tooltipArrow: {
    color: palette.neutral[200],
  },
  icon: {
    marginTop: rem(4),
  },
}));
