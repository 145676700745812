import { FC, HTMLAttributes } from 'react';

import { IconProps } from '@/Atoms/Icons/Icons.type';
import themeFoundation from '@/designSystem/theme';

const CalendarSolidIcon: FC<IconProps & HTMLAttributes<HTMLDivElement>> = ({
  size = 26,
  className,
  color = themeFoundation.palette.neutral[300],
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8 7.59998C10.3581 7.59998 9.99998 7.95815 9.99998 8.39998V9.19998H9.19998C8.31632 9.19998 7.59998 9.91632 7.59998 10.8V18.8C7.59998 19.6836 8.31632 20.4 9.19998 20.4H18.8C19.6836 20.4 20.4 19.6836 20.4 18.8V10.8C20.4 9.91632 19.6836 9.19998 18.8 9.19998H18V8.39998C18 7.95815 17.6418 7.59998 17.2 7.59998C16.7581 7.59998 16.4 7.95815 16.4 8.39998V9.19998H11.6V8.39998C11.6 7.95815 11.2418 7.59998 10.8 7.59998ZM10.8 11.6C10.3581 11.6 9.99998 11.9581 9.99998 12.4C9.99998 12.8418 10.3581 13.2 10.8 13.2H17.2C17.6418 13.2 18 12.8418 18 12.4C18 11.9581 17.6418 11.6 17.2 11.6H10.8Z"
      fill={color}
    />
  </svg>
);

export default CalendarSolidIcon;
